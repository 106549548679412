// @flow

import React from "react";
import {
    withPrivateRoute,
    Layout,
    AdminUserManagementLayout,
    InternalPersons,
} from "@containers";

/**
 * Admin Gebruikers Beheer - Interne Personen
 */
const AdminInternalPersons = (props: *) => {
    return (
        <Layout {...props}>
            <AdminUserManagementLayout>
                <InternalPersons id="internal-persons" />
            </AdminUserManagementLayout>
        </Layout>
    );
};

export default withPrivateRoute(AdminInternalPersons);
